import { useCallback, useState } from 'react';
import ConditionForm from './ConditionForm';
import { showToast } from '../../components/Toast/showToast';
import { conditionService } from '../../api/services';
import { ConditionFormValues } from '../../types/Condition.types';
import { getCreateData } from './utils';

interface SuspenseCreateConditionProps {
  closeContent: () => void;
}

const SuspenseCreateCondition = ({ closeContent }: SuspenseCreateConditionProps) => {
  const [initialData] = useState<ConditionFormValues>({
    name: '',
    description: '',
    options: [{ optionId: '', optionValue: '' }],
  });

  const handleSubmit = useCallback(async (data: ConditionFormValues) => {
    const preparedData = getCreateData(data);
    const successfullyCreated = await conditionService.create(preparedData);
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar condição.',
      });
      closeContent();
    }
  }, []);

  return (
    <>
      <ConditionForm
        type="create"
        boxType="suspense-page"
        initialData={initialData}
        onSubmit={handleSubmit}
        onCancel={closeContent}
      />
    </>
  );
};

export default SuspenseCreateCondition;
