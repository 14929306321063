import { useCallback, useState } from 'react';
import ConditionForm from './ConditionForm';
import { showToast } from '../../components/Toast/showToast';
import { conditionService } from '../../api/services';
import { ConditionFormValues } from '../../types/Condition.types';
import { getCreateData } from './utils';
import { useNavigate } from 'react-router-dom';

const CreateCondition = () => {
  const [initialData] = useState<ConditionFormValues>({
    name: '',
    description: '',
    options: [{ optionId: '', optionValue: '' }],
  });

  const navigate = useNavigate();
  const navigateToListConditions = useCallback(() => {
    navigate('/conditions');
  }, []);

  const handleSubmit = useCallback(async (data: ConditionFormValues) => {
    const preparedData = getCreateData(data);
    const successfullyCreated = await conditionService.create(preparedData);
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar condição.',
      });
      navigateToListConditions();
    }
  }, []);

  return (
    <ConditionForm
      type="create"
      initialData={initialData}
      onSubmit={handleSubmit}
      onCancel={navigateToListConditions}
    />
  );
};

export default CreateCondition;
