import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicVariableForm from './DynamicVariableForm';
import { DynamicVariableFormValues } from '../../types/DynamicVariable.types';
import { showToast } from '../../components/Toast/showToast';
import { dynamicVariableService, variableService } from '../../api/services';

const UpdateDynamicVariable = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [relationType, setRelationType] = useState<string>('');
  const [initialData, setInitialData] = useState<DynamicVariableFormValues>({
    principalVariableId: id as string,
  });

  const navigate = useNavigate();
  const navigateToListVariables = useCallback(() => {
    navigate('/variables');
  }, []);

  useEffect(() => {
    async function getInitialData() {
      const variableData = await variableService.getById(id as string);
      const principalVariableId =
        variableData?.tipoRelacao === 'Principal'
          ? variableData?.idVariavel
          : variableData!.variavelRelacao![0].idVariavelPrincipal;
      setRelationType(variableData?.tipoRelacao as string);

      let principalVariableOptions: string[];
      let dependentVariableOptions: string[];
      if (variableData?.tipoRelacao === 'Principal') {
        const relationVariableData = await variableService.getById(
          variableData!.variavelRelacao![0].idVariavelDependente
        );
        principalVariableOptions = variableData!.valorAuxiliar!.split('¨');
        dependentVariableOptions = relationVariableData!.valorAuxiliar!.split('¨');
      } else {
        const relationVariableData = await variableService.getById(
          variableData!.variavelRelacao![0].idVariavelPrincipal
        );
        principalVariableOptions = relationVariableData!.valorAuxiliar!.split('¨');
        dependentVariableOptions = variableData!.valorAuxiliar!.split('¨');
      }

      setInitialData({
        principalVariableId: principalVariableId as string,
        dependentVariableId: {
          label: variableData!.variavelRelacao![0].nomeVariavelDependente,
          value: variableData!.variavelRelacao![0].idVariavelDependente,
        },
        values: variableData?.variavelRelacao
          ?.filter(
            (relationVariable) =>
              principalVariableOptions.includes(relationVariable.valorVariavelPrincipal) &&
              dependentVariableOptions.includes(relationVariable.valorVariavelDependente)
          )
          .map((relationVariable) => ({
            principalVariableValue: {
              label: relationVariable.valorVariavelPrincipal,
              value: relationVariable.valorVariavelPrincipal,
            },
            dependentVariableValue: {
              label: relationVariable.valorVariavelDependente,
              value: relationVariable.valorVariavelDependente,
            },
          })),
      });
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: DynamicVariableFormValues) => {
    const successfullyUpdated = await dynamicVariableService.update({
      idVariavelPrincipal: data.principalVariableId,
      idVariavelDependente: data?.dependentVariableId?.value,
      associacoes: data?.values?.map((association) => ({
        valorVariavelPrincipal: association.principalVariableValue.value,
        valorVariavelDependente: association.dependentVariableValue.value,
      })),
    });
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao atualizar variável dinâmica.',
      });
      navigateToListVariables();
    }
  }, []);

  return !isLoading ? (
    <>
      <DynamicVariableForm relationType={relationType} initialData={initialData} onSubmit={handleSubmit} />
    </>
  ) : null;
};

export default UpdateDynamicVariable;
