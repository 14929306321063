import { DynamicVariableDataCreateRequest, DynamicVariableDataCreateResponse } from '../../types/DynamicVariable.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IDynamicVariableApiClient {
  create(data: DynamicVariableDataCreateRequest): Promise<DynamicVariableDataCreateResponse | undefined>;
  update(data: DynamicVariableDataCreateRequest): Promise<DynamicVariableDataCreateResponse | undefined>;
}

export class DynamicVariableApiClient implements IDynamicVariableApiClient {
  variableApiClient: IApiClient;

  constructor(DynamicVariableApiClient: IApiClient) {
    this.variableApiClient = DynamicVariableApiClient;
  }

  async create(data: DynamicVariableDataCreateRequest): Promise<DynamicVariableDataCreateResponse | undefined> {
    const response = await this.variableApiClient.post<
      DynamicVariableDataCreateRequest,
      DynamicVariableDataCreateResponse
    >(`/variaveis-inteligentes`, data);
    return response;
  }

  async update(data: DynamicVariableDataCreateRequest): Promise<DynamicVariableDataCreateResponse | undefined> {
    const response = await this.variableApiClient.put<
      DynamicVariableDataCreateRequest,
      DynamicVariableDataCreateResponse
    >(`/variaveis-inteligentes`, data);
    return response;
  }
}

export default class DynamicVariableService {
  variableApiClient: IDynamicVariableApiClient;

  constructor() {
    this.variableApiClient = new DynamicVariableApiClient(new ApiClient());
  }

  async create(data: DynamicVariableDataCreateRequest): Promise<DynamicVariableDataCreateResponse | undefined> {
    return this.variableApiClient.create(data);
  }

  async update(data: DynamicVariableDataCreateRequest): Promise<DynamicVariableDataCreateResponse | undefined> {
    return this.variableApiClient.update(data);
  }
}
