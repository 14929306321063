import { useCallback, useEffect, useRef, useState } from 'react';
import FullPage from '../../components/FullPage';
import { DocumentDataForm, DocumentFlow, DocumentTextItem } from '../../types/Document.types';
import { InitialDataForm, InitialDataContent, InitialDataFormValues } from './InitialData';
import ProgressBar from '../../components/ProgressBar';
import { ConditionData } from '../../types/Condition.types';
import {
  conditionAssociationService,
  conditionService,
  documentService,
  extratoLogService,
  modelService,
  templateService,
  textService,
} from '../../api/services';
import DocumentConditionForm from './DocumentConditionForm';
import { SingleTextData } from '../../types/Text.types';
import DocumentTextForm, { DocumentTextFormHandle } from './DocumentTextForm';
import DocumentPreview from './DocumentPreview';
import {
  StepLogModel,
  getConditionValue,
  getCreateUpdateData,
  getDocumentFlow,
  getDynamicVariable,
  getInitialDataText,
  getInitialVariableValues,
  getLogStep,
  getNextFlow,
  getProgress,
  getTemplate,
  replaceVariableValues,
  stringifyWithArrays,
} from './utils';
import DocumentSaveOptions from './DocumentSaveOptions';
import DropdownMenu from '../../components/DropdownMenu';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { PreviewFooter, StyledDocumentSaveOptions, TextContainer } from './styles';
import TextEditor from '../Texts/TextEditor';
import Box from '../../components/Box';
import Button from '../../components/Form/Button';
import { useNavigate } from 'react-router-dom';
import { ModelFlow, SingleModelData } from '../../types/Model.types';
import { showToast } from '../../components/Toast/showToast';
import { ConditionAssociationData } from '../../types/ConditionAssociation.types';
import { TemplateDocument } from '../../types/Template.types';
import FinishDocument from './FinishDocument';
import { SingleVariableData } from '../../types/Variable.types';
import Loading from '../../components/Loading';

const CreateUpdateDocument = () => {
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState('initial');
  const [isPreview, setIsPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState<InitialDataFormValues>({
    name: '',
    model: { label: '', value: '' },
    folder: '',
    client: '',
    clientName: '',
    template: { label: '', value: '' },
  });
  const [documentId, setDocumentId] = useState<string>('');
  const [documentFlow, setDocumentFlow] = useState<DocumentFlow[]>([]);
  const [documentData, setDocumentData] = useState<DocumentDataForm>({});
  const [documentLog] = useState<StepLogModel[]>([]);
  const [initialModelId, setInitialModelId] = useState<string>('');
  const [subModelId, setSubModelId] = useState<string>('');
  const [subModelElement, setSubModelElement] = useState<string>('');
  const [initialModel, setInitialModel] = useState<SingleModelData>();
  const [currentModel, setCurrentModel] = useState<SingleModelData>();
  const [models, setModels] = useState<SingleModelData[]>([]);
  const [initialFlow, setInitialFlow] = useState<ModelFlow>();
  const [sequenceFlow, setSequenceFlow] = useState<{ flow: ModelFlow; model: string }[]>([]);
  const [currentFlow, setCurrentFlow] = useState<ModelFlow | null>(null);
  const [textItems, setTextItems] = useState<DocumentTextItem>({});
  const [currentConditionId, setCurrentConditionId] = useState<string>();
  const [currentCondition, setCurrentCondition] = useState<ConditionData>();
  const [conditionAssociationData, setConditionAssociationData] = useState<ConditionAssociationData[]>();
  const [currentTextId, setCurrentTextId] = useState<string>();
  const [currentText, setCurrentText] = useState<SingleTextData>();
  const [initialDataText, setInitialDataText] = useState<DocumentDataForm>({});
  const [textEditor, setTextEditor] = useState<string>('');
  const [variablesProcess, setVariablesProcess] = useState<{ [key: string]: string }>({});
  const [templateId, setTemplateId] = useState<string>();
  const [loadingForm, setLoadingForm] = useState(false);
  const isFirstStep = useRef(true);
  const [template, setTemplate] = useState<TemplateDocument>({
    font: 'IBM Plex Sans',
    font_size: { label: '13pt', value: '13pt' },
    page_size: { label: 'Carta', value: 'Carta' },
    margin: {
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
    },
    header: [],
    topic: [],
    footer: [],
  });

  const documentPreviewRef = useRef<HTMLInputElement>(null);
  const documentFormRef = useRef<DocumentTextFormHandle>(null);

  const navigate = useNavigate();
  const navigateToListDocuments = useCallback(() => {
    navigate('/documents');
  }, []);

  const onChangeVariable = useCallback(
    (documentDataVariables: DocumentDataForm) => {
      const currentTextItem = textItems[currentTextId as string];
      const documentDataWithDynamicVariables: DocumentDataForm = getDynamicVariable(
        currentText,
        documentDataVariables,
        documentFormRef
      );
      const treatedText = replaceVariableValues(
        currentTextItem,
        documentDataWithDynamicVariables,
        currentText!.variaveis
      );
      setTextItems((textItems) => ({ ...textItems, [currentTextId as string]: treatedText }));
    },
    [currentText, textItems]
  );

  const onEditorChange = useCallback((content: string) => setTextEditor(content), []);

  const onInitialSave = useCallback(
    async (data: InitialDataFormValues) => {
      setInitialData(() => data);
      setLoadingForm(true);
      try {
        if (data.client || data.folder) {
          const processesResult = await documentService.getProcesses(data.client, data.folder);
          if (!processesResult) return;
          if (processesResult?.processos && processesResult?.processos.length > 0) {
            const firstProcess = processesResult.processos[0];

            data.clientName = firstProcess?.nomeCliente;

            let variablesProcessResult = {};
            for (const [key, value] of Object.entries(firstProcess)) {
              variablesProcessResult = { ...variablesProcessResult, [key.toLowerCase()]: value };
            }
            setVariablesProcess(variablesProcessResult);
          }
        }

        setTemplateId(data.template.value);

        if (documentId) {
          const updatedDocument = await documentService.update(
            documentId,
            getCreateUpdateData(initialData, data.template.value, getText(), documentFlow, false)
          );
          if (updatedDocument) {
            setDocumentFlow([]);
            setInitialModelId(() => data.model.value);
          }
        } else {
          const createdDocument = await documentService.create(
            getCreateUpdateData({ ...initialData, ...data }, data.template.value, '', documentFlow, false)
          );
          if (createdDocument) {
            setDocumentId(createdDocument?.idDocumento as string);
            setInitialModelId(data.model.value);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoadingForm(false);
      }
    },
    [documentId, initialFlow, currentFlow, initialModelId]
  );

  const onFinalSave = useCallback(async () => {
    console.log(documentLog);
    console.log(stringifyWithArrays(documentLog));
    const updatedDocument = await documentService.update(
      documentId,
      getCreateUpdateData(initialData, templateId, textEditor, documentFlow, true)
    );
    const extratoLog = await extratoLogService.create({
      idDocumento: documentId,
      logJson: stringifyWithArrays(documentLog),
    });
    if (updatedDocument) {
      console.log(extratoLog);
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar documento.',
      });
      navigateToListDocuments();
    }
  }, [initialData, templateId, documentId, textEditor, documentFlow]);

  const switchPreview = useCallback(() => {
    setIsPreview((preview) => !preview);
  }, []);

  const onNext = useCallback(
    async (data: DocumentDataForm) => {
      setIsLoading(true);
      setDocumentData((documentData) => ({ ...documentData, ...data }));
      documentLog.push(getLogStep('next', data, currentFlow, currentText, currentCondition));
      try {
        let modelFlow = currentModel?.fluxosResponse as ModelFlow[];
        let nextFlow = getNextFlow(step, data, currentFlow, modelFlow, currentCondition);
        const newDocumentFlow = getDocumentFlow(
          step,
          textItems,
          data,
          currentText,
          documentFlow,
          currentFlow,
          currentCondition
        );

        setStep('loading');

        setSequenceFlow((sequenceFlow) => [
          ...sequenceFlow,
          { flow: currentFlow as ModelFlow, model: currentModel?.idModelo as string },
        ]);

        if (nextFlow?.modeloTipoFluxo?.descricao === 'Final' && currentModel?.idModelo !== initialModelId) {
          setCurrentModel(initialModel);
          setSubModelId('');
          modelFlow = initialModel?.fluxosResponse as ModelFlow[];
          const submodelFlow = modelFlow.find((flow) => flow.chaveElemento === subModelElement);
          const connectionFlow = modelFlow.find((flow) => flow.chaveElemento === submodelFlow?.chaveElementoSaida);
          nextFlow = modelFlow.find((flow) => flow.chaveElemento === connectionFlow?.chaveElementoSaida);
        }
        setCurrentFlow(nextFlow as ModelFlow);
        setDocumentFlow(newDocumentFlow);

        await documentService.update(
          documentId,
          getCreateUpdateData(initialData, templateId, getText(), newDocumentFlow, false)
        );

        /* task 7349 - Automação step documento sem intervenção redator */
        MoveStepAutomatic(modelFlow, nextFlow, data);
        setTimeout(() => setIsLoading(false), 200);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoadingForm(false);
      }
    },
    [currentFlow, documentFlow, step, documentId, textItems]
  );

  async function MoveStepAutomatic(
    modelFlow: ModelFlow[] | undefined,
    nextFlow: ModelFlow | undefined,
    data: DocumentDataForm
  ) {
    let connectionFlow: ModelFlow | undefined;

    if (nextFlow?.modeloTipoFluxo?.descricao == 'Texto') {
      const textData = await textService.getById(nextFlow?.textoResponse?.idTexto as string);
      const textoComVariavel = textData?.variaveis.length == 0 ? false : true;

      if (!textoComVariavel) {
        connectionFlow = modelFlow?.find((flow) => flow.chaveElemento === nextFlow?.chaveElementoSaida);
        nextFlow = modelFlow?.find((flow) => flow.chaveElemento === connectionFlow?.chaveElementoSaida);

        setStep('loading');
        setCurrentFlow(nextFlow as ModelFlow);
        const newSequenceFlow = sequenceFlow;
        setSequenceFlow(() => newSequenceFlow);

        setTimeout(() => MoveStepAutomatic(modelFlow, nextFlow, data), 1000);
      } else {
        setTimeout(() => AvancaAutomatico(), 1000);
      }
    } else if (nextFlow?.modeloTipoFluxo?.descricao === 'Final' && currentModel?.idModelo !== initialModelId) {
      if (initialModel != undefined) {
        setCurrentModel(initialModel);
        setSubModelId('');
        modelFlow = initialModel?.fluxosResponse as ModelFlow[];
        const submodelFlow = modelFlow.find((flow) => flow.chaveElemento === subModelElement);
        const connectionFlow = modelFlow.find((flow) => flow.chaveElemento === submodelFlow?.chaveElementoSaida);
        nextFlow = modelFlow.find((flow) => flow.chaveElemento === connectionFlow?.chaveElementoSaida);

        setStep('loading');
        setCurrentFlow(nextFlow as ModelFlow);
        const newSequenceFlow = sequenceFlow;
        setSequenceFlow(() => newSequenceFlow);

        setTimeout(() => MoveStepAutomatic(modelFlow, nextFlow, data), 1000);
      }
    } else {
      setTimeout(() => AvancaAutomatico(), 1000);
    }
  }

  // Task 9648 - Avançar o formulário automáticamente
  const AvancaAutomatico = function () {
    if (isFirstStep && isFirstStep.current === true) {
      isFirstStep.current = false;
      return;
    }

    // Seleciona todos os botões
    const buttons = document.querySelectorAll('button');

    // Encontra o botão que contém o texto 'Próximo'
    const nextButton = Array.from(buttons).find((button) => button.textContent?.trim() === 'Próximo');
    // Verifica se existe input de arquivo no formulario
    const fileInput = document.querySelector('form input[type=file]');
    // Verifica se existe input opcional no formulario
    const optionalInput = document.querySelector('form [data-optional=true]');

    // Simula o clique no botão encontrado desde que não tenha input de arquivo
    if (nextButton && fileInput == null && optionalInput == null) {
      nextButton.click();
      console.log('Etapa avançada.');
    } else {
      console.log('Botão "Próximo" não encontrado.');
    }
  };

  const onBack = useCallback(() => {
    setIsLoading(true);
    documentLog.push(getLogStep('back', null, currentFlow, currentText, currentCondition));
    if (currentFlow?.modeloTipoFluxo.descricao == 'Texto') {
      const newTextItems = textItems;
      delete newTextItems[currentFlow?.textoResponse?.idTexto as string];
      setTextItems(() => newTextItems);
    }
    const newDocumentFlow: DocumentFlow[] = documentFlow;
    newDocumentFlow.pop();
    setDocumentFlow(newDocumentFlow);
    if (sequenceFlow.length > 0) {
      setCurrentTextId(undefined);
      setCurrentConditionId(undefined);
      const lastFlow = sequenceFlow[sequenceFlow.length - 1];
      setSubModelId('');
      if (lastFlow.model !== currentModel?.idModelo) {
        const model = models?.find((model) => model.idModelo === lastFlow.model);
        setSubModelId('');
        setCurrentModel(model);
      }
      setStep('loading');
      setCurrentFlow(lastFlow.flow);
      const newSequenceFlow = sequenceFlow;
      newSequenceFlow.pop();
      setSequenceFlow(() => newSequenceFlow);
    } else {
      setCurrentFlow(null);
      setCurrentTextId(undefined);
      setCurrentConditionId(undefined);
      setInitialModelId('');
      setStep('initial');
      setProgress(0);
    }
    setTimeout(() => setIsLoading(false), 200);
  }, [currentFlow, documentFlow]);

  const getConditionDefaultValue = useCallback(() => {
    return getConditionValue(currentCondition, [], documentData, conditionAssociationData);
  }, [documentData, currentCondition]);

  const getText = useCallback(() => {
    /* istanbul ignore next */
    return Object.keys(textItems).length > 0
      ? Object.values(textItems).reduce((finalText, value) => finalText + value)
      : '';
  }, [textItems]);

  const scrollToBottom = useCallback(() => {
    if (documentPreviewRef.current)
      documentPreviewRef.current.scrollTop = documentPreviewRef?.current?.scrollHeight || 0;
  }, [documentPreviewRef]);

  const calculateProgress = useCallback(() => {
    if (!initialModel) return;
    const element = currentModel?.idModelo !== initialModelId ? subModelElement : currentFlow?.chaveElemento;
    setProgress(getProgress(initialModel, element, step));
  }, [currentFlow, currentModel, initialModelId, initialModel, step]);

  useEffect(() => {
    (async () => {
      if (currentConditionId) {
        const conditionData = await conditionService.getById(currentConditionId as string);
        setStep('condition');
        setCurrentCondition(conditionData);
      }
    })();
  }, [currentConditionId]);

  useEffect(() => {
    (async () => {
      if (currentTextId) {
        const textData = await textService.getById(currentTextId as string);
        setStep('text');
        setCurrentText(textData);

        let currentText = textData?.valorTexto as string;
        if ((currentTextId as string) in textItems) {
          currentText = textItems[currentTextId as string];
        }
        const initialData = getInitialDataText(currentText);

        const initialVariableValues = getInitialVariableValues(initialData, textData, variablesProcess, documentData);

        setInitialDataText(initialVariableValues);

        const textWithInitialVariables = replaceVariableValues(
          initialData.treatedText,
          initialVariableValues,
          textData?.variaveis as SingleVariableData[]
        );

        setTextItems((textItems) => {
          if ((currentTextId as string) in textItems) {
            return textItems;
          } else {
            const newTextItems = textItems;
            newTextItems[currentTextId as string] = textWithInitialVariables;
            return newTextItems;
          }
        });
      }
    })();
  }, [currentTextId]);

  useEffect(() => {
    (async () => {
      if (initialModelId && initialModelId != '') {
        const modelResult = await modelService.getById(initialModelId);
        setInitialModel(modelResult);
        const initialFlow = modelResult?.fluxosResponse.find((flow) => flow.modeloTipoFluxo.descricao === 'Inicio');
        const connectionFlow = modelResult?.fluxosResponse.find(
          (flow) => flow.chaveElemento === initialFlow?.chaveElementoSaida
        );
        const currentFlow = modelResult?.fluxosResponse.find(
          (flow) => flow.chaveElemento === connectionFlow?.chaveElementoSaida
        );
        setInitialFlow(currentFlow);
        setCurrentFlow(currentFlow as ModelFlow);
        setModels((models) => [...models, modelResult] as SingleModelData[]);
        setCurrentModel(modelResult);
        setDocumentFlow([]);
        setTextItems({});

        /* task 7434 - Automatizar passagem step se a primeira página do documento for um texto simples */
        MoveStepAutomatic(modelResult?.fluxosResponse, currentFlow, {});
      }
    })();
  }, [initialModelId]);

  useEffect(() => {
    (async () => {
      if (subModelId && subModelId != '') {
        const modelResult = await modelService.getById(subModelId);
        const initialFlow = modelResult?.fluxosResponse.find((flow) => flow.modeloTipoFluxo.descricao === 'Inicio');
        const connectionFlow = modelResult?.fluxosResponse.find(
          (flow) => flow.chaveElemento === initialFlow?.chaveElementoSaida
        );
        const currentFlow = modelResult?.fluxosResponse.find(
          (flow) => flow.chaveElemento === connectionFlow?.chaveElementoSaida
        );
        setModels((models) => [...(models as SingleModelData[]), modelResult] as SingleModelData[]);
        setCurrentFlow(currentFlow as ModelFlow);
        setCurrentModel(modelResult);
      }
    })();
  }, [subModelId]);

  useEffect(() => {
    if (currentModel) {
      if (currentFlow?.modeloTipoFluxo.descricao === 'Texto') {
        setCurrentConditionId(undefined);
        setCurrentTextId(currentFlow?.textoResponse?.idTexto);
      }
      if (currentFlow?.modeloTipoFluxo.descricao === 'Condição') {
        setCurrentTextId(undefined);
        setCurrentConditionId(currentFlow?.modeloCondicaoResponse?.idModeloCondicao);
      }
      if (currentFlow?.modeloTipoFluxo.descricao === 'Sub-modelo') {
        setSubModelId(currentFlow?.subModeloResponse?.idModelo as string);
        setSubModelElement(currentFlow?.chaveElemento as string);
      }
      if (currentFlow?.modeloTipoFluxo.descricao === 'Final') {
        setCurrentTextId(undefined);
        setCurrentConditionId(undefined);
        setStep('final');
      }
    }
  }, [currentFlow]);

  useEffect(() => {
    (async () => {
      if (currentModel) {
        const conditionAssociationDataResult = await conditionAssociationService.list(currentModel?.idModelo as string);
        setConditionAssociationData(conditionAssociationDataResult);
      }
    })();
  }, [currentModel]);

  useEffect(() => {
    (async () => {
      if (templateId) {
        const templateDataResult = await templateService.getById(templateId);
        setTemplate(getTemplate(templateDataResult));
      }
    })();
  }, [templateId]);

  useEffect(() => {
    try {
      calculateProgress();
    } catch (error) {
      console.log(error);
    }
  }, [step]);

  return (
    <>
      {isPreview && (
        <FullPage
          sidebar={null}
          content={
            <>
              <Box
                padding={false}
                border={false}
                background={false}
                type="full-page"
                footer={
                  <PreviewFooter>
                    <Button kind="secondary" onClick={switchPreview}>
                      Voltar para a edição
                    </Button>
                    <Button onClick={onFinalSave}>Salvar</Button>
                  </PreviewFooter>
                }>
                <TextContainer>
                  <TextEditor
                    type="revision"
                    onEditorChange={onEditorChange}
                    disabled={false}
                    initialValue={getText()}
                    template={template}
                  />
                </TextContainer>
              </Box>
            </>
          }
        />
      )}
      {!isLoading ? (
        <FullPage
          ref={documentPreviewRef}
          sidebar={
            <>
              <ProgressBar progress={progress} />
              {step === 'initial' && (
                <InitialDataForm
                  initialData={initialData}
                  loading={loadingForm}
                  onSubmit={onInitialSave}
                  onBack={navigateToListDocuments}
                />
              )}
              {step === 'final' && <FinishDocument name={initialData.name} onBack={onBack} onSave={switchPreview} />}
              {step === 'condition' && (
                <>
                  <DocumentConditionForm
                    name={currentCondition?.idModeloCondicao as string}
                    defaultValue={getConditionDefaultValue()}
                    condition={currentCondition}
                    onNext={onNext}
                    onBack={onBack}
                    loading={loadingForm}
                  />
                </>
              )}
              {step === 'text' && (
                <DocumentTextForm
                  ref={documentFormRef}
                  initialData={initialDataText}
                  text={currentText}
                  onChangeVariable={onChangeVariable}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}
            </>
          }
          content={
            <>
              {Object.keys(textItems).length <= 0 ? (
                <InitialDataContent />
              ) : (
                <DocumentPreview scrollToBottom={scrollToBottom} template={template} text={getText()} />
              )}
              {step !== 'initial' && (
                <StyledDocumentSaveOptions>
                  <DropdownMenu
                    content={<DocumentSaveOptions onSave={navigateToListDocuments} openPreview={switchPreview} />}>
                    <EllipsisVerticalIcon data-testid="document-save-options" />
                  </DropdownMenu>
                </StyledDocumentSaveOptions>
              )}
            </>
          }
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CreateUpdateDocument;
