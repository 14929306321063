/* eslint-disable react/jsx-no-bind */
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useCallback } from 'react';
import { OptionsOrGroups, GroupBase, components, MultiValueProps, OptionProps } from 'react-select';
import { CheckSelect } from './CheckSelect';
import { MoreItems, StyledSelect } from './styles';

export interface OptionType {
  readonly label: string;
  readonly value: string;
}
interface MoreSelectedBadgeProps {
  items: unknown[];
}
const MoreSelectedBadge = ({ items }: MoreSelectedBadgeProps) => {
  const title = items.join(', ');
  const label = `+ ${items.length}`;

  return <MoreItems title={title}>{label}</MoreItems>;
};

const MultiValue = (props: MultiValueProps) => {
  const { index, getValue } = props;
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x: any) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const Option = (props: OptionProps) => {
  const onChange = useCallback(() => null, []);
  return (
    <div>
      <components.Option {...props}>
        <CheckSelect isChecked={props.isSelected} setIsChecked={onChange} label={props.label} />
      </components.Option>
    </div>
  );
};

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>Sem opções disponíveis</span>
    </components.NoOptionsMessage>
  );
};

const DropdownIndicator = (props: any) => {
  return props.selectProps.menuIsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />;
};

interface SelectProps extends React.PropsWithoutRef<JSX.IntrinsicElements['input']> {
  placeholder?: string;
  defaultValue?: string;
  selectedOption?: OptionType;
  isMulti?: boolean;
  options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined;
  name?: string;
  onChange?: (val: any) => any;
  onInputChange?: (inputValue: string) => void;
}
export const Select = React.forwardRef<HTMLInputElement, SelectProps>(({ options, onInputChange, ...props }, ref) => {
  const components = props.isMulti
    ? { MultiValue, Option, DropdownIndicator, NoOptionsMessage }
    : { DropdownIndicator, NoOptionsMessage };

  return (
    <StyledSelect
      className="react-select-container"
      classNamePrefix="react-select"
      hideSelectedOptions={false}
      components={components}
      options={options}
      isClearable={false}
      isDisabled={props.disabled}
      defaultValue={props.selectedOption}
      onInputChange={onInputChange}
      {...props}
    />
  );
});
Select.displayName = 'Select';
