export const permissionsList: { [key: string]: { label: string; value: string } } = {
  'areas-juridicas': { label: 'Áreas jurídicas', value: 'areas-juridicas' },
  secoes: { label: 'Seções', value: 'secoes' },
  variaveis: { label: 'Variáveis', value: 'variaveis' },
  textos: { label: 'Textos', value: 'textos' },
  modelos: { label: 'Modelos', value: 'modelos' },
  condicoes: { label: 'Condições', value: 'condicoes' },
  documentos: { label: 'Documentos', value: 'documentos' },
  templates: { label: 'Templates', value: 'templates' },
  usuarios: { label: 'Usuários', value: 'usuarios' },
};

export const pageByPermission: { [key: string]: string } = {
  'areas-juridicas': '/legal-areas',
  secoes: '/sections',
  variaveis: '/variables',
  textos: '/texts',
  modelos: '/models',
  condicoes: '/conditions',
  documentos: '/documents',
  templates: '/templates',
  usuarios: '/users',
};
