import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DynamicVariableFormValues } from '../../types/DynamicVariable.types';
import { showToast } from '../../components/Toast/showToast';
import { dynamicVariableService } from '../../api/services';
import DynamicVariableForm from './DynamicVariableForm';

const CreateDynamicVariable = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateToListVariables = useCallback(() => {
    navigate('/variables');
  }, []);

  const handleSubmit = useCallback(async (data: DynamicVariableFormValues) => {
    const successfullyCreated = await dynamicVariableService.create({
      idVariavelPrincipal: data.principalVariableId,
      idVariavelDependente: data?.dependentVariableId?.value,
      associacoes: data?.values?.map((association) => ({
        valorVariavelPrincipal: association.principalVariableValue.value,
        valorVariavelDependente: association.dependentVariableValue.value,
      })),
    });
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar variável dinâmica.',
      });
      navigateToListVariables();
    }
  }, []);

  return (
    <>
      <DynamicVariableForm
        initialData={{
          principalVariableId: id as string,
        }}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateDynamicVariable;
