import { ConditionFormValues, SingleConditionData } from '../../types/Condition.types';

export const getCreateData = (data: ConditionFormValues) => {
  return {
    titulo: data.name,
    descricao: data.description,
    condicaoOpcaoDescricoes: data?.options?.map((option) => option.optionValue) as string[],
  };
};

export const getUpdateData = (data: ConditionFormValues) => {
  return {
    titulo: data.name,
    descricao: data.description,
    opcoes: data?.options?.map((option) => ({
      optionId: option.optionId == '' ? null : option.optionId,
      optionValue: option.optionValue,
    })),
  };
};

export const getFormattedInitialData = (conditionData: SingleConditionData | undefined) => {
  return {
    name: conditionData?.titulo,
    description: conditionData?.descricao,
    options: conditionData?.condicaoOpcoes.map((option) => ({
      optionId: option.idModeloCondicaoOpcao,
      optionValue: option.descricao,
    })),
    quantityOfModels: conditionData?.quantidadeModelos,
  };
};
