import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConditionFormValues } from '../../types/Condition.types';
import FormGroup from '../../components/Form/FormGroup';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Box from '../../components/Box';
import Heading from '../../components/Text/Heading';
import { StyledForm } from './styles';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import Paragraph from '../../components/Text/Paragraph';
import { DialogConfirmAction } from '../shared/DialogConfirmAction';

interface ConditionFormProps {
  type: 'create' | 'update' | 'duplicate';
  boxType?: 'page' | 'suspense-page';
  initialData?: ConditionFormValues;
  onSubmit: (data: ConditionFormValues) => void;
  onCancel: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required('O campo nome é obrigatório'),
  options: yup
    .array()
    .of(
      yup.object().shape({
        optionValue: yup.string().required('O valor da opção é obrigatório'),
      })
    )
    .min(1, 'É necessário pelo menos uma opção.'),
});

const ConditionForm = ({ type, boxType = 'page', initialData, onSubmit, onCancel }: ConditionFormProps) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<ConditionFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray({
    name: 'options',
    control,
  });
  const appendValue = useCallback(
    () =>
      append({
        optionId: '',
        optionValue: '',
      }),
    []
  );
  const removeValue = useCallback(
    (index: number) => () => {
      remove(index);
    },
    []
  );

  const title = {
    create: 'Criar nova condição',
    update: 'Editar condição',
    duplicate: 'Duplicar condição',
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box
          type={boxType}
          footer={
            <>
              <Button className="cancel-button" type="button" kind="alert" onClick={onCancel}>
                Voltar
              </Button>
              {type === 'create' || (initialData?.quantityOfModels as number) <= 0 ? (
                <Button className="submit-button" type="submit">
                  Salvar
                </Button>
              ) : (
                <DialogConfirmAction
                  type="update"
                  trigger={<Button className="submit-button">Salvar</Button>}
                  title="Confirmar alteração?"
                  text={`Tem certeza que deseja alterar a condição ${initialData?.name}? Esta mesma condição está sendo utilizada em ${initialData?.quantityOfModels} modelos e a sua alteração impactará em todos os MODELOS.`}
                  onConfirm={handleSubmit(onSubmit)}
                />
              )}
            </>
          }>
          <div className="content">
            <Heading>{title[type]}</Heading>
            <Box border={false} padding={false}>
              <FormGroup error={errors.name?.message}>
                <Input {...register('name')} label="Nome da condição" />
              </FormGroup>
              <FormGroup error={errors.description?.message}>
                <Input {...register('description')} label="Descrição" />
              </FormGroup>
              <FormGroup error={errors?.options?.message}>
                <Box className="value-container">
                  <Paragraph className="label">Opções</Paragraph>
                  {fields.map((field, index) => {
                    return (
                      <section key={field.id}>
                        <FormGroup error={errors?.options && errors.options[index]?.optionValue?.message}>
                          <Input type="hidden" {...register(`options.${index}.optionId`)} />
                          <Input {...register(`options.${index}.optionValue`)} />
                        </FormGroup>
                        <Button type="button" kind="outline" onClick={removeValue(index)}>
                          <TrashIcon />
                        </Button>
                      </section>
                    );
                  })}
                  <div className="button-container">
                    <Button type="button" kind="outline" onClick={appendValue}>
                      <PlusIcon className="add-option" />
                      Adicionar opção
                    </Button>
                  </div>
                </Box>
              </FormGroup>
            </Box>
          </div>
        </Box>
      </StyledForm>
    </>
  );
};

export default ConditionForm;
