import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[4]};

    button {
      width: 25rem;
    }
  }

  .search-filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[1.5]};

    input {
      width: 40rem;
    }
  }
`;

export const StyledForm = styled.form`
  padding-bottom: 10rem;
  > div {
    width: 100%;
  }
  h1 {
    margin-bottom: ${theme.spacings[4]};
  }
  .switch {
    margin-top: ${theme.spacings[2]};
  }
  .subheader {
    margin: ${theme.spacings[4]} 0 ${theme.spacings[2]};
    font-size: ${theme.fonts['medium']};
  }
  .default-value {
    margin-top: ${theme.spacings[1]};
  }
  .value-container {
    position: relative;
    margin: ${theme.spacings[3]} 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    p {
      width: 100%;
    }
    section {
      width: 100%;
      margin: ${theme.spacings[0.5]} 0;
      display: flex;
      div {
        width: 100%;
      }
    }
    button {
      text-align: right;
      svg {
        height: 2rem;
        stroke: ${theme.colors.grey[400]};
        margin-right: 0;
        &:hover {
          stroke: ${theme.colors.grey[700]};
        }
        &.add-option {
          stroke: ${theme.colors.blue[600]};
          margin-right: 1rem;
        }
      }
    }
  }
  .association {
    .vinculation {
      margin-top: ${theme.spacings[0.5]};
    }
  }
`;
