import BlankCircle from '../../assets/icons/blank-circle.svg';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '../../components/Box';
import Button from '../../components/Form/Button';
import { DynamicVariableContainer, Steps, StyledForm } from './styles';
import Paragraph from '../../components/Text/Paragraph';
import Heading from '../../components/Text/Heading';
import { variableService } from '../../api/services';
import { OptionType, Select } from '../../components/Form/Select';
import { DialogConfirmAction } from '../shared/DialogConfirmAction';
import { VariableData } from '../../types/Variable.types';
import { DynamicVariableFormValues } from '../../types/DynamicVariable.types';
import { getBadge, multipleChoiceTypeName, suspenseListTypeName } from './utils';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import FormGroup from '../../components/Form/FormGroup';

interface DynamicVariableFormProps {
  initialData?: DynamicVariableFormValues;
  relationType?: string;
  onSubmit: (data: DynamicVariableFormValues) => void;
}

const validationSchema = yup.object({
  dependentVariableId: yup.object().shape({
    label: yup.string().required('O campo de variável dependente é obrigatório'),
    value: yup.string().required(),
  }),
  values: yup
    .array()
    .of(
      yup.object().shape({
        principalVariableValue: yup.object().shape({
          label: yup.string().required('O valor da variável principal é obrigatório'),
          value: yup.string().required(),
        }),
        dependentVariableValue: yup.object().shape({
          label: yup.string().required('O valor da variável dependente é obrigatório'),
          value: yup.string().required(),
        }),
      })
    )
    .min(1, 'É necessária pelo menos uma associação de valores.'),
});

const DynamicVariableForm = ({ initialData, relationType, onSubmit }: DynamicVariableFormProps) => {
  const [step, setStep] = useState(initialData?.dependentVariableId ? 2 : 1);
  const [principalVariable, setPrincipalVariable] = useState<VariableData>();
  const [dependentVariable, setDependentVariable] = useState<VariableData>();
  const [variables, setVariables] = useState<VariableData[]>();
  const [variablesOptions, setVariablesOptions] = useState<readonly OptionType[]>();
  const [principalVariableOptions, setPrincipalVariableOptions] = useState<readonly OptionType[]>();
  const [dependentVariableOptions, setDependentVariableOptions] = useState<readonly OptionType[]>();

  const {
    control,
    watch,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<DynamicVariableFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });
  const watchDependentVariableId = watch('dependentVariableId');
  const { fields, append, remove } = useFieldArray({
    name: 'values',
    control,
  });
  const formValues = useWatch({
    name: 'values',
    control,
  });
  const appendValue = useCallback(
    () =>
      append({
        principalVariableValue: {
          label: '',
          value: '',
        },
        dependentVariableValue: {
          label: '',
          value: '',
        },
      }),
    []
  );
  const removeValue = useCallback(
    (index: number) => () => {
      remove(index);
    },
    []
  );

  useEffect(() => {
    async function getPrincipalVariable() {
      const principalVariableData = await variableService.getById(initialData!.principalVariableId);
      setPrincipalVariable(principalVariableData);
    }

    async function getVariables() {
      const variables = await variableService.list();
      setVariables(variables);
    }

    getPrincipalVariable();
    getVariables();
  }, []);

  useEffect(() => {
    const variablesOptionsData = variables
      ?.filter(
        (variable) =>
          (variable.descricaoTipoVariavel === multipleChoiceTypeName ||
            variable.descricaoTipoVariavel === suspenseListTypeName) &&
          variable.idVariavel !== initialData!.principalVariableId &&
          variable.tipoRelacao !== 'Principal' &&
          variable.tipoRelacao !== 'Dependente'
      )
      .map(
        (variable) =>
          ({
            label: variable.nomeVariavel,
            value: variable.idVariavel,
          } as OptionType)
      );
    setVariablesOptions(variablesOptionsData);
  }, [variables]);

  useEffect(() => {
    async function getDependentVariable() {
      const value = (watchDependentVariableId as unknown as OptionType).value;
      const dependentVariableData = await variableService.getById(value);
      setDependentVariable(dependentVariableData);
      if (value !== initialData?.dependentVariableId?.value) {
        formValues?.forEach((value, index) => {
          setValue(`values.${index}.dependentVariableValue.value`, '');
          setValue(`values.${index}.dependentVariableValue.label`, '');
        });
      }
    }

    if (watchDependentVariableId) {
      getDependentVariable();
    }
  }, [watchDependentVariableId]);

  useEffect(() => {
    const selectedOptionsPrincipalVariable: string[] | undefined = formValues?.map(
      (value) => value.principalVariableValue.value
    );
    setPrincipalVariableOptions(
      principalVariable?.valorAuxiliar?.split('¨').map((option) => ({
        label: option,
        value: option,
        isDisabled: selectedOptionsPrincipalVariable?.includes(option),
      }))
    );
  }, [principalVariable, formValues]);

  useEffect(() => {
    setDependentVariableOptions(
      dependentVariable?.valorAuxiliar?.split('¨').map((option) => ({
        label: option,
        value: option,
      }))
    );
  }, [dependentVariable, formValues]);

  const navigate = useNavigate();
  const navigateToVariables = useCallback(() => {
    navigate(`/variables`);
  }, []);

  const renderSelectVariablesOptions = useCallback(
    ({ field }: any) => (
      <Select
        placeholder="Selecione uma opção"
        disabled={relationType === 'Dependente'}
        {...field}
        options={variablesOptions}
      />
    ),
    [variablesOptions]
  );

  const renderSelectPrincipalVariable = useCallback(
    ({ field }: any) => {
      return <Select {...field} placeholder="Selecione uma opção" options={principalVariableOptions} />;
    },
    [principalVariableOptions]
  );

  const renderSelectDependentVariable = useCallback(
    ({ field }: any) => <Select {...field} placeholder="Selecione uma opção" options={dependentVariableOptions} />,
    [dependentVariableOptions]
  );

  const changeToSecondStep = useCallback(() => {
    setStep(2);
    setTimeout(() => clearErrors(), 0);
  }, [watchDependentVariableId]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <DynamicVariableContainer>
        <Box
          padding={false}
          border={false}
          footer={
            <>
              <Button type="button" kind="alert" onClick={navigateToVariables}>
                Cancelar
              </Button>
              {step === 1 ? (
                <Button type="button" onClick={changeToSecondStep} disabled={!watchDependentVariableId}>
                  Avançar
                </Button>
              ) : (
                <Button type="submit" disabled={formValues && formValues?.length < 1}>
                  Finalizar
                </Button>
              )}
            </>
          }>
          <Box>
            <Steps>
              <div>
                <img src={BlankCircle} /> <Paragraph>Variável principal</Paragraph>
              </div>
              <div className="separator"></div>
              <div className={step === 1 ? 'disabled' : ''}>
                <img src={BlankCircle} /> <Paragraph>Aplicação</Paragraph>
              </div>
            </Steps>
          </Box>
          <Box>
            <Heading size="medium">Variável dinâmica</Heading>
            <Paragraph>
              Aqui você irá vincular comportamentos de uma variável principal a dados de uma variável dependente.
            </Paragraph>
            <div className="variable-info">
              <div>
                <Paragraph className="key">Nome da variável principal</Paragraph>
                <div className="value">{principalVariable?.nomeVariavel}</div>
              </div>
              <div>
                <Paragraph className="key">Tipo</Paragraph>
                <div className="value">{principalVariable?.descricaoTipoVariavel}</div>
              </div>
              <div>
                <Paragraph className="key">Característica</Paragraph>
                <div className="value">{getBadge(principalVariable?.tipoRelacao as string)}</div>
              </div>
            </div>
            <FormGroup error={errors.dependentVariableId?.label?.message}>
              <Paragraph className="label">Escolha a variável dependente</Paragraph>
              <Controller name="dependentVariableId" control={control} render={renderSelectVariablesOptions} />
            </FormGroup>

            {step === 2 && (
              <FormGroup error={errors?.values?.message}>
                <div className="second-step">
                  <Heading size="medium">Dados de impacto</Heading>
                  <Paragraph>Vincule os dados que deseja impactar na hora da redação.</Paragraph>

                  {fields.map((field, index) => {
                    return (
                      <Box className="value-container" key={field.id}>
                        <section key={field.id}>
                          <FormGroup
                            error={errors?.values && errors.values[index]?.principalVariableValue?.label?.message}>
                            <Paragraph className="label">Dados da variável principal</Paragraph>
                            <Controller
                              name={`values.${index}.principalVariableValue`}
                              control={control}
                              render={renderSelectPrincipalVariable}
                            />
                          </FormGroup>
                          <div className="value-separator">
                            <Paragraph size="small">Vincular à</Paragraph>
                          </div>
                          <FormGroup
                            error={errors?.values && errors.values[index]?.dependentVariableValue?.label?.message}>
                            <Paragraph className="label">Dados da variável dependente</Paragraph>
                            <Controller
                              name={`values.${index}.dependentVariableValue`}
                              control={control}
                              render={renderSelectDependentVariable}
                            />
                          </FormGroup>

                          <DialogConfirmAction
                            type="delete"
                            trigger={
                              <Button kind="outline" type="button">
                                <XMarkIcon />
                              </Button>
                            }
                            title="Confirmar exclusão?"
                            text={`Tem certeza que deseja excluir essa relação?`}
                            onConfirm={removeValue(index)}
                          />
                        </section>
                      </Box>
                    );
                  })}

                  <div className="button-container">
                    <Button type="button" kind="outline" onClick={appendValue}>
                      <PlusIcon />
                      Adicionar vínculo
                    </Button>
                  </div>
                </div>
              </FormGroup>
            )}
          </Box>
        </Box>
      </DynamicVariableContainer>
    </StyledForm>
  );
};

export default DynamicVariableForm;
