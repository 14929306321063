import { EllipsisVerticalIcon, PencilIcon } from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';
import { templateService } from '../../api/services';
import { showToast } from '../../components/Toast/showToast';

interface TemplateActionsProps {
  id: string;
  name: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
}

const TemplateActions = ({ id, name, setDeletedItem }: TemplateActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);

  const navigate = useNavigate();
  const navigateToUpdateTemplate = useCallback(() => {
    navigate(`/templates/${id}/update`);
  }, []);

  const removeTemplate = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await templateService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover template.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={navigateToUpdateTemplate}>
            <PencilIcon /> <span>Editar template</span>
          </Item>

          <Separator />

          <DialogCancelDelete itemName={name} itemType="template" removeItem={removeTemplate(id)} />
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default TemplateActions;
