import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConditionForm from './ConditionForm';
import { ConditionFormValues } from '../../types/Condition.types';
import { conditionService } from '../../api/services';
import { getFormattedInitialData, getUpdateData } from './utils';
import { showToast } from '../../components/Toast/showToast';

const UpdateCondition = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<ConditionFormValues>({
    name: '',
    description: '',
    options: [{ optionId: '', optionValue: '' }],
    quantityOfModels: 0,
  });

  const navigate = useNavigate();
  const navigateToListConditions = useCallback(() => {
    navigate('/conditions');
  }, []);

  useEffect(() => {
    async function getInitialData() {
      const conditionData = await conditionService.getById(id as string);
      setInitialData(getFormattedInitialData(conditionData));
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: ConditionFormValues) => {
    const successfullyUpdated = await conditionService.update(id as string, getUpdateData(data));
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao editar condição.',
      });
      navigateToListConditions();
    }
  }, []);

  return !isLoading ? (
    <>
      <ConditionForm
        type="update"
        initialData={initialData}
        onSubmit={handleSubmit}
        onCancel={navigateToListConditions}
      />
    </>
  ) : null;
};

export default UpdateCondition;
