import React from 'react';
import { Container, CheckboxInput, Check, Label } from './styles';

export interface CheckboxProps {
  id: string;
  name: string;
  label: string;
  value: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({ ...props }, ref) => {
  const { id, label } = props;

  return (
    <Container>
      <Label htmlFor={id}>
        <CheckboxInput ref={ref} {...props} />
        <Check className="check" />
        <span>{label}</span>
      </Label>
    </Container>
  );
});

Checkbox.displayName = 'Checkbox';
export default Checkbox;
